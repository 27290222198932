import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import TaxAgent from '../../assets/img/agent-badge.png'
import NTAA from '../../assets/img/ntaa-badge-3.png'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                {/* <Link to={`${process.env.PUBLIC_URL + "/"}`}> */}
                                    <img src={NTAA} alt="NTAA Badge" />
                                    <img src={TaxAgent} alt="Tax Agent Membership Badge"/>
                            {/*   </Link> */}

                                <Text>
                                    Proud member of the National Tax and Accountants' Association Ltd. (NTAA) <br /><br /> Tax Practitioner Board Member, <br />Agent Number 71096002.
                                </Text>

                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} 
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our Company</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Contact us</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our Services</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <li><a href="https://www.facebook.com/groups/431797990234" target={'_blank'} rel="noopener noreferrer">Facebook Group</a></li>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                <b>
                            Suite 1, 23A Old Geelong Road
                            Hoppers Crossing, Vic 3029
                            </b>
                            </address>
                            <br />
                            <b>email:</b> <a href="mailto:reception@quinns.net.au">reception@quinns.net.au</a>
                            <br />
                            <br />
                            <b>ABN:</b> 69 801 725 029
                            <br />
                            <b>Tax Agent:</b> 7109 6002
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;