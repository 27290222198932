import React from "react";
import { Link } from "react-router-dom";

const About = () => (
  <div className="about-area-wrapper sm-top">
    <div className="container">
      <div className="row align-items-lg-center">
        <div className="col-md-9 col-lg-12">
          <h2>Covid-19 Protocols</h2>
          <p>To our valued clientele,</p>
          <p>
            When visiting our office we ask that you consider the following if you are an
            existing or prospective client:
          </p>
          <ul class="covid">
            <li>
              To ensure that there is sufficient space in our waiting room, we
              may ask you to wait in your car or outside if there is
              insufficient space to maintain distances between clients.
            </li>
            <li>
              Our waiting and meeting areas are sanitised between clients.
            </li>
            <li>
              We ask that you limit the number of business partners, and family
              members, during your visit to only people needed for the interview.
            </li>
            <li>
              Please re-schedule if you have a sore throat, increased
              temperature, persistent cough, or any of the other known symptoms
              of Covid-19.
            </li>
            <li>
              Every effort will be made to ensure the safety of our clientele
              and staff, and we thank you in advance for your cooperation during
              this time.
            </li>
            <li>
              <b>
                Please note: We have been offering, and will continue to offer,
                our full range of services via video conferencing, email, and
                via telephone.
              </b>
            </li>
          </ul>
          <Link to={"/"}>Back to Home Page</Link>
        </div>
      </div>
    </div>
  </div>
);

export default About;
