import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import history from "./helpers/History";

// imports
import Home from "./pages/Home";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import About from "./pages/About";
import Covid from "./pages/Covid-19";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";

const App = () => {
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/services"}`}
            component={Service}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service/:serviceID"}`}
            component={ServiceDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/covid-19"}`}
            component={Covid}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route exact component={Error404} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
