import React, { Fragment } from "react";

import Header from "../components/Header";
import Slider from "../components/Slider/home/";
import About from "../components/About/home";
import Services from "../components/Services";
import CallToMap from "../components/CallToMap";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials/home";
import MobileMenu from "../components/MobileMenu";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <Slider />
      <About />
      <Services />
      <Testimonials />
      <CallToMap />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default Home;
