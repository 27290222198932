import React from 'react';
import FormInput from "../../components/UI/Input";
import emailjs from 'emailjs-com';
import history from '../../helpers/History';

export default function Form(){
    if(!window.location.hash) {
        window.location = window.location + '#go';
        console.log('#go');
        window.location.reload();
    }

    window.onload = function() {
        document.getElementById('contact-form').addEventListener('submit', function(event) {
            event.preventDefault();
            console.log('#go loaded');
            emailjs.init("user_dQuyzqq7CMDE7S1tZUJG1");
            emailjs.sendForm('mailgun', 'contact_form_quinn', this)
            .then(function(response) {
                history.push('/');
                console.log(response.status, response.text);
            }, function(error) {
                console.log(error); // handled?
                history.push('/contact');
            });

        });
    }

    return (
        <div className="contact-form-wrap">
            <form id="contact-form">
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'first_name'}
                            placeholder={'First Name *'}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'last_name'}
                            placeholder={'Last Name *'}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email_address'}
                            placeholder={'Email address *'}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'phone_no'}
                            placeholder={'Phone No'}
                        />
                    </div>

                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}
                            name={'con_message'}
                            placeholder={'Write Your Message *'}
                        />

                        <FormInput
                            tag={'button'}
                            classes={'btn-outline'}
                        />
                        <div className="form-message"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

