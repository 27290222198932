import React, { Fragment } from "react";
import Header from "../components/Header";
import CovidUpdate from "../components/CovidUpdate/CovidUpdate";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const PageCovid = () => {
  return (
    <Fragment>
      <Header />
      <CovidUpdate />
      <CallToAction />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageCovid;
