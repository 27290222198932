import React from 'react';

function CallToMap() {
    return (
        <div className="call-top-action-wrap sp-y">
            <div className="container">
                <div className="footer-top-content">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-3">
                            <h2>Find Us Here</h2>
                           
                        </div>
                        <div className="col-md-12 col-lg-9">
                            <div className="contact-map">
                            <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1081.6561807555404!2d144.70006496093578!3d-37.882922571008486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzfCsDUyJzU3LjciUyAxNDTCsDQxJzU5LjYiRQ!5e0!3m2!1sen!2sau!4v1585791139048!5m2!1sen!2sau" width="100%" height="350" frameBorder="0" allowFullScreen aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallToMap;