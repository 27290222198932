import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/home";
import Services from "../components/Services";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import Testimonial from "../components/Testimonials/home";
import ServiceThumb from '../assets/img/about.jpg'

const PageAbout = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header-m.jpg')}
                title="ABOUT US"
                content="Quinn's Taxation are the experts in personal income and company tax in the City of Wyndham."
            />
            <About
                title={'The Second header in About'}
                heading="With a fancy <br/> sub-line "
                thumb={ServiceThumb}
                content="<b>Quinn's</b> has the third line in this section, which needs copywriting and show be almost but not quite entirley this length."
            />
            <Services/>
            <Testimonial/>
            <CallToAction/>
            <Footer/>
            <MobileMenu/>
        </Fragment>
    );
};
 
export default PageAbout;