import React from 'react';
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";

const ServiceContentWrap = ({service,totalService,prevService,nextService}) => {
    return (
        <div className="col-lg-8">
            <div className="service-details-content">
                <div className="service-details-info">
                    <h3>{service.title}</h3>
                    <p>{service.shortDesc}</p>

                    <div className="about-service mt-50 mt-sm-35">
                        <h4>About this Service</h4>
                        {
                            <div dangerouslySetInnerHTML={{__html: service.aboutServiceDesc}}/>
                        }
                        <List classes="service-feature mt-30">
                            {
                                service.features.map((feature,indx)=>(
                                    <LI key={indx}>{feature}</LI>
                                ))
                            }
                        </List>
                    </div>

                    <div className="pagination-wrap">
                        <ul className="pagination">
                            <li className="prev">
                                <a href={`${process.env.PUBLIC_URL + `/service/${prevService.title.split(' ').join('-').toLocaleLowerCase()}?id=${prevService.id}`}`}
                                   className={service.id === 1 ? "disabled" : null}
                                >
                                    <i className="fa fa-long-arrow-left"/> Previous
                                </a>
                            </li>
                            <li className="next">
                                <a href={`${process.env.PUBLIC_URL + `/service/${nextService.title.split(' ').join('-').toLocaleLowerCase()}?id=${nextService.id}`}`}
                                   className={service.id === totalService ? "disabled" : null}
                                >
                                    Next <i className="fa fa-long-arrow-right"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceContentWrap;