import React,{Fragment} from 'react';
import socialNetworks from '../../data/SocialNetworks/socials'

const ContactInfo = ({address}) => {
    return (
        <Fragment>
            <div className="widget-item m-0">
                <address>
                    <span dangerouslySetInnerHTML={{__html: address}}/>
                </address>
            </div>
            <div className="widget-item m-0">
                <br /><br />
                <p><b>Office Hours: </b></p>
                <p> Mon - Thu: 9am to 5pm</p>
                <p>Friday: 9am to 4pm</p>
                <p>Evenings and Saturdays Available</p>
                <p>By Appointment Only</p>
{/*                 <b>Office Hours:</b><br />Mon to Thu:  9am - 5pm <br />Friday: 9am - 4pm <br />Evening and Saturday Appointments<br />Available (By Appointment Only)
 */}            </div>
            
            <div className="member-social-icons mt-30">
                {
                    socialNetworks.map(social=>(
                        <a key={social.id} href={`https://${social.networkName}.com/${social.username}`} target="_blank" rel="noopener noreferrer">
                            <i className={`fa fa-${social.networkName}`}/>
                        </a>
                    ))
                }
            </div>
        </Fragment>
    );
};

export default ContactInfo;