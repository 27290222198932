import React,{Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactPage from "../templates/Contact";
import MobileMenu from "../components/MobileMenu";
import CallToMap from "../components/CallToMap";

const PageContact = () => {
    return (
        <Fragment>
            <Header/>
            <ContactPage/>
            <CallToMap />
            <Footer/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageContact;