import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import Testimonial from "../components/Testimonials/home";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about.jpg'

const PageService = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="OUR SERVICES"
                content="We offer a full range of Taxation services to individuals and businesses."
            />
            <PageAbout
                title={'Our Services'}
                heading="Personal & Business <br />Taxation Services"
                thumb={ServiceThumb}
                content="<b>Quinn's Taxation</b> are a family business operating for 50 years to serve the individuals and businesses of Western Melbourne. Located adjacent to Hoppers Crossing Station, we are a short walk from public transport, with a wheelchair lift to our first floor office available. <br /><br /><b>During this unfortunate period of physical distancing</b>, we are more than happy to supply email, telephone and video conferencing services to continue providing our services to our clients."
            />
            <Services classes="sm-top"/>
            <Testimonial/>
            <CallToAction/>
            <Footer/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageService;